<template>
    <div :class="isPCH5Text !== 'PC' ? 'Shop_ApprovalInfoDetail' : 'Shop_ApprovalInfoDetail_PC'">
        <div :class="isPCH5Text !== 'PC' ? 'Shop_ApprovalInfoList_bottom' : 'Shop_ApprovalInfoList_bottom_PC'">
            <div style="width: 50%;text-align: center;border-right: 1px solid #ccc;box-sizing: border-box;">
                <i class="el-icon-tickets" style="font-size: 30px;color: #D1100B;"></i>
            </div>
            <div style="width: 50%;text-align: center;" @click="$router.push('./RG_UserInfo')">
                <i class="el-icon-user-solid" style="font-size: 30px;"></i>
            </div>
        </div>
        <div :style="isPCH5Text !== 'PC' ? '' : 'padding: 0 10px;'">
            <el-card style="width: 100%;height: calc(100vh - 80px);overflow-y: auto;" body-style="padding: 10px;">
                <div
                    style="position: sticky;top: 0;left: 0;background-color: #fff;width: 100%;line-height: 35px;text-align: center;height: 35px;font-weight: bold;z-index: 10;">

                    <i class="el-icon-back"
                        style="font-size: 28px;cursor: pointer;position: absolute;left: 0;top: 0;z-index: 1;"
                        @click="$router.push('./RG_ApprovalInfoList')"></i>

                    <span v-if="type == 0" style="font-size: 16px;">待审信息</span>
                    <span v-if="type == 1">已审信息</span>
                </div>


                <div class="centent">
                    <div v-if="TheData.ApprovalName" class="info">
                        <div class="ellipsis"
                            style="font-size: 16px;text-align: center;line-height: 30px;font-weight: bold;">
                            {{ TheData.ApprovalName }}
                        </div>
                        <div class="ellipsis1" style="font-size: 14px;text-align: center;line-height: 30px;">
                            {{ TheData.ApprovalCode }}
                        </div>
                        <el-descriptions class="margin-top" :column="1" size="small" border>
                            <el-descriptions-item label="项目名称">
                                <div class="ellipsis1">第{{ TaskData.ProjectName }}批次</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="任务名称">
                                <div class="ellipsis1">第{{ TaskData.TaskName }}批次</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="报审类型">
                                <div class="ellipsis1">{{ ApprovalType == 1 ? '设备材料报审' : '深化图纸报审' }}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="报审批次">
                                <div class="ellipsis1">第{{ TheData.ApprovalBatch }}批次</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="报审单位">
                                <div class="ellipsis1">{{
                                    TheData.ReportingUnit
                                }}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="报审人">
                                <div class="ellipsis1">{{
                                    TheData.ReportingName
                                }}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="报审日期">
                                <div class="ellipsis1">{{
                                    TheData.ReportingDate ? TheData.ReportingDate.substr(0, 19) : ''
                                }}</div>
                            </el-descriptions-item>
                            <el-descriptions-item label="关联附件">

                                <div v-for="item in UploadList" :key="item.Id">
                                    <div @click="upClick(item)" class="ellipsis1"
                                        style="margin-bottom: 5px;color: #D1100B;line-height: 1.6;font-size: 12px;cursor: pointer;font-weight: bold;s">
                                        {{ item.FileName }}
                                    </div>
                                </div>
                            </el-descriptions-item>

                        </el-descriptions>
                    </div>
                    <div v-else>
                        <el-empty description="加载中"></el-empty>
                    </div>
                    <el-tabs type="border-card" stretch v-if="signData.length > 0" style="margin-top: 20px;">
                        <el-tab-pane v-for="(item, index) in signData" :key="item.Id" :label="'待签' + (index + 1)"
                            v-loading.fullscreen.lock="loading">
                            <el-form label-width="120px" style="font-weight: bold;" class="ViewAuditForm"
                                label-position="top" size="small" :disabled="item.disabled || type == 1">
                                <el-form-item :label="item.ApprovalOrganName + '：'">
                                    <el-select v-model="item.ApprovalOpinions" clearable placeholder="请选择批复意见"
                                        style="width: 100%">
                                        <el-option v-for="v in ApprovalOpinions" :key="v.DicName" :label="v.DicName"
                                            :value="v.DicName">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="批注意见：">
                                    <el-select v-model="item.ApprovalAnnotation" clearable placeholder="请选择批注意见"
                                        style="width: 100%">
                                        <el-option v-for="v in AnnotationOpinions" :key="v.DicName" :label="v.DicName"
                                            :value="v.DicName">
                                        </el-option>
                                    </el-select>
                                    <el-input type="textarea" style="margin-top: 5px;" placeholder="请输入批注意见备注"
                                        v-model="item.ApprovalARemark" id="ApprovalARemark"
                                        @focus="scrollToFocusedElement">
                                    </el-input>
                                </el-form-item>
                                <!-- <el-form-item label="签署："
                                v-if="ApprovalType == 1 && (isPCH5Text === '微信' || isPCH5Text === 'H5')">
                                <div @click="signVisibleOpen(item)"
                                    style="height: 160px;border: 1px solid #ccc;line-height: 160px;text-align: center;width: 100%;box-sizing: border-box;background-color: #fff;"
                                    v-if="!item.ApproverSignature && type == 0">
                                    请点击签名
                                </div>
                                <div style="height: 160px;text-align: center;width: 100%;box-sizing: border-box;background-color: #fff;"
                                    @click="signVisibleOpen(item)" v-if="item.ApproverSignature">
                                    <img :src="item.ApproverSignature" alt=""
                                        style=" height: 100%;width: 100%;object-fit: contain;">
                                </div>
                            </el-form-item>
                            <el-form-item label="附件：" v-if="ApprovalType == 1 && isPCH5Text === 'PC'">
                                <UploadValue v-model="item.AuditUpFileData"
                                    @success="propValidateField($event, item, index)" :id="item.Id" ref="UploadValue"
                                    @error="errorValidateField($event, item, index)"
                                    upUrl="/ReviewGO/Review_ApprovalSignInfo_FAL/OssUploadFileByForm"
                                    delUrl="/ReviewGO/Review_ApprovalSignInfo_FAL/DeleteData">
                                </UploadValue>
                            </el-form-item> -->
                                <el-form-item>
                                    <el-date-picker v-model="item.ApprovalDateTime" type="date"
                                        value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="选择日期"
                                        @focus="scrollToFocusedElement" id="ApprovalDateTime">
                                    </el-date-picker>
                                </el-form-item>
                                <!-- //图纸报审 -->
                                <!-- <el-form-item :label="item.ApprovalOrganName + '：'" v-if="ApprovalType == 2">
                                    <el-select v-model="item.ApprovalOpinions" clearable placeholder=" 请选择意见归类"
                                        style="width: 100%">
                                        <el-option v-for="v in OpinionClassification" :key="v.DicName"
                                            :label="v.DicName" :value="v.DicName">
                                        </el-option>
                                    </el-select>
                                </el-form-item> -->
                                <!-- <el-form-item v-if="ApprovalType == 2">
                                    <el-input type="textarea" style="margin-top: 5px;" placeholder="请输入备注"
                                        v-model="item.ApprovalARemark">
                                    </el-input>
                                </el-form-item> -->

                                <el-form-item label="附件：">
                                    <UploadValue v-model="item.AuditUpFileData"
                                        @success="propValidateField($event, item, index)" :id="item.Id"
                                        ref="UploadValue" @error="errorValidateField($event, item, index)"
                                        upUrl="/ReviewGO/Review_ApprovalSignInfo_FAL/OssUploadFileByForm"
                                        delUrl="/ReviewGO/Review_ApprovalSignInfo_FAL/DeleteData" v-if="type == 0">
                                    </UploadValue>
                                    <div v-else>
                                        <div v-for="val in item.AuditUpFileData" :key="val.Id">
                                            <div @click="upClick(val)" class="ellipsis1"
                                                style="margin-bottom: 5px;color: #D1100B;font-size: 12px;line-height: 1.2;">
                                                {{ val.FileName }}
                                            </div>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="签署：" v-if="(isPCH5Text === '微信' || isPCH5Text === 'H5')">
                                    <div v-if="type == 0">
                                        <div @click="signVisibleOpen(item)"
                                            style="height: 160px;border: 1px solid #ccc;line-height: 160px;text-align: center;width: 100%;box-sizing: border-box;background-color: #fff;"
                                            v-if="!item.ApproverSignature && type == 0">
                                            {{ item.disabled ? '暂无签名' : '请点击签名' }}
                                        </div>
                                        <div style="height: 160px;text-align: center;width: 100%;box-sizing: border-box;background-color: #fff;border: #ccc 1px solid;"
                                            @click="signVisibleOpen(item)" v-if="item.ApproverSignature && type == 0">
                                            <img :src="item.ApproverSignature" alt=""
                                                style=" height: 100%;width: 100%;object-fit: contain;">
                                        </div>
                                    </div>
                                    <div v-if="type == 1">
                                        <div style="height: 160px;border: 1px solid #ccc;line-height: 160px;text-align: center;width: 100%;box-sizing: border-box;background-color: #fff;"
                                            v-if="!item.ApproverSignature">
                                            暂无签名
                                        </div>
                                        <div style="height: 160px;text-align: center;width: 100%;box-sizing: border-box;background-color: #fff;border: #ccc 1px solid;"
                                            v-if="item.ApproverSignature">
                                            <img :src="item.ApproverSignature" alt=""
                                                style=" height: 100%;width: 100%;object-fit: contain;">
                                        </div>
                                    </div>
                                </el-form-item>

                                <el-form-item v-if="type == 0">
                                    <div style="display: flex;">
                                        <el-button type="primary" @click="saveData(item, index, 1)"
                                            style="width: 50%;">暂存</el-button>
                                        <el-button type="primary" @click="saveData(item, index, 2)"
                                            style="width: 50%;">提交</el-button>
                                    </div>
                                </el-form-item>
                            </el-form>

                        </el-tab-pane>
                    </el-tabs>
                    <div v-else>
                        <el-empty description="加载中"></el-empty>
                    </div>
                </div>
            </el-card>
        </div>

        <div v-if="signVisible" style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;z-index: 99;">
            <sign @close="signVisible = false" @success="signSub"></sign>
        </div>

    </div>
</template>
<script>

import sign from '../../components/Sign/sign.vue'
import UploadValue from "./UploadValue.vue";
export default {
    data() {
        return {
            ApprovalType: '',
            signVisible: false,
            loading: false,
            src: '',
            userInfo: {},
            RowId: '',
            TheData: {},
            UploadList: [],
            signData: [],
            signItem: {},
            tabsList: [],
            // 待审0: 已审1
            type: '',
            //批复意见-材料报审-内
            ApprovalOpinions: [],
            //批注意见-材料报审-内
            AnnotationOpinions: [],
            // 意见归类-图纸审核-内
            OpinionClassification: [],
            // 商铺信息: 
            ShopData: {},
            TaskId: '',
            //上传附件
            addAuditUpFileData: [],
            signUPId: "",
            //暂存1 提交2
            num: 0,
            TaskData: []
        };
    },
    props: {},
    components: {
        sign, UploadValue
    },
    methods: {
        getSystemInfo() {
            if (/(micromessenger)/i.test(navigator.userAgent)) {
                return "微信"
            } else {
                // 判断h5还是pc true就是h5
                let client =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    );
                if (client) {
                    return "H5"
                } else {
                    return "PC"
                }
            }
        },
        //附件上传成功后避免组件验证失效
        propValidateField(data, item, index) {
            this.loading = false
            if (this.num === 1) {
                this.$phoneMessage({
                    message: '暂存成功',
                })
            } else if (this.num === 2) {
                this.$phoneMessage({
                    message: '提交成功',
                })
            }
            this.$set(item, 'disabled', true)
        },
        errorValidateField(data, item) {
            this.loading = false
            console.log(data, 'errorValidateField')
            let str = ''
            data.forEach(val => {
                str = val.name + ',' + str
            })

            this.$phoneMessage({
                message: str + '文件上传失败',
            })
            this.$set(item, 'disabled', false)

        },
        scrollToFocusedElement(e) {
            // setTimeout(() => {
            //     console.log(e)
            //     var a = document.createElement('a');
            //     // 设置a标签的href属性，指向页面内的一个锚点
            //     a.href = '#' + e.id;
            //     a.click();
            // }, 2000)
        },
        signVisibleOpen(item) {
            if (item.disabled) {
                return
            }
            if (this.type == 1) {
                return
            }
            this.signItem = {}
            this.signVisible = true
            this.signItem = item
        },
        saveData(item, index, num) {
            let str = ''
            if (num === 1) {
                str = '请确保信息已填写完毕，确定暂存吗?'
            } else if (num === 2) {
                str = '请确保信息已填写完毕，确定提交吗?'
            }
            this.$confirm(str, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                customClass: (this.isPCH5Text === '微信' || this.isPCH5Text === 'H5') ? 'h5-confirm-class' : ''
            }).then(() => {
                let arr = []
                // if (this.ApprovalType == 1) {
                if (item.ApprovalOpinions) {
                    arr.push({
                        "Condition": "ApprovalOpinions",
                        "Keyword": item.ApprovalOpinions
                    })
                } else {
                    return this.$phoneMessage({
                        message: '请选择批复意见',
                    })
                }
                if (item.ApprovalAnnotation) {
                    arr.push({
                        "Condition": "ApprovalAnnotation",
                        "Keyword": item.ApprovalAnnotation
                    })
                } else {
                    return this.$phoneMessage({
                        message: '请选择批注意见',
                    })
                }

                if (item.ApprovalDateTime) {
                    arr.push({
                        "Condition": "ApprovalDateTime",
                        "Keyword": item.ApprovalDateTime
                    })
                } else {
                    return this.$phoneMessage({
                        message: '请选择日期',
                    })
                }


                // } 
                // else if (this.ApprovalType == 2) {
                //     if (item.ApprovalOpinions) {
                //         arr.push({
                //             "Condition": "ApprovalOpinions",
                //             "Keyword": item.ApprovalOpinions
                //         })
                //     } else {
                //         return this.$phoneMessage({
                //             message: '请选择意见分类',
                //         })
                //     }
                // }
                if (item.ApprovalARemark) {
                    arr.push({
                        "Condition": "ApprovalARemark",
                        "Keyword": item.ApprovalARemark
                    })
                }
                if (num === 2) {
                    if (item.ApproverSignature) {
                        arr.push({
                            "Condition": "ApproverSignature",
                            "Keyword": item.ApproverSignature
                        })
                    } else {
                        return this.$phoneMessage({
                            message: '请确认是否签名后再提交',
                        })
                    }
                    if (item.AuditUpFileData.length == 0) {
                        this.$confirm('附件暂无上传，确定提交吗？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                            customClass: (this.isPCH5Text === '微信' || this.isPCH5Text === 'H5') ? 'h5-confirm-class' : ''
                        }).then(() => {
                            arr.push({
                                "Condition": "ApprovalStatus",
                                "Keyword": '1'
                            })
                            this.num = num
                            this.loading = true
                            this.$http.post('/ReviewGO/Review_ApprovalSignInfo/UpdateDataByCondition', {
                                "Conditions": arr,
                                "Ids": [
                                    item.Id
                                ]
                            }).then(async res => {
                                this.loading = false
                                if (res.Success) {
                                    this.$phoneMessage({
                                        message: '提交成功',
                                    })
                                    this.$set(item, 'disabled', true)
                                } else {
                                    this.$set(item, 'disabled', false)
                                    this.$phoneMessage({
                                        message: res.Msg,
                                    })
                                }
                            })
                        })
                        return
                    }
                    arr.push({
                        "Condition": "ApprovalStatus",
                        "Keyword": '1'
                    })
                }
                this.num = num
                this.loading = true
                this.$http.post('/ReviewGO/Review_ApprovalSignInfo/UpdateDataByCondition', {
                    "Conditions": arr,
                    "Ids": [
                        item.Id
                    ]
                }).then(async res => {
                    if (res.Success) {
                        if (item.AuditUpFileData.length > 0) {
                            let flag = item.AuditUpFileData.every(val => {
                                return val.status === 'success'
                            })
                            if (flag) {
                                if (num === 1) {
                                    this.loading = false
                                    this.$set(item, 'disabled', true)
                                    return this.$phoneMessage({
                                        message: '暂存成功',
                                    })

                                } else if (num === 2) {
                                    this.loading = false
                                    this.$set(item, 'disabled', true)
                                    return this.$phoneMessage({
                                        message: '提交成功',
                                    })
                                }
                            } else {
                                await setTimeout(async () => {
                                    await this.$refs.UploadValue[index].upload()
                                })
                            }
                        } else {
                            if (num === 1) {
                                this.loading = false
                                this.$set(item, 'disabled', true)
                                return this.$phoneMessage({
                                    message: '暂存成功',
                                })
                            } else if (num === 2) {
                                this.loading = false
                                this.$set(item, 'disabled', true)
                                return this.$phoneMessage({
                                    message: '提交成功',
                                })
                            }
                        }


                    } else {
                        this.$phoneMessage({
                            message: res.Msg,
                        })
                    }
                })
            })

        },
        // 图审字典 	批复意见-内 1 材料 2 图纸
        getApprovalOpinions(type) {
            this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
                    Condition: "DicKey",
                    Keyword: type == 1 ? 'RG_ApprovalOpinions_CL' : 'RG_ApprovalOpinions_TS',
                }).then(res => {
                    if (res.Success) {
                        this.ApprovalOpinions = res.Data.DictionaryDetailsList || []
                    }
                })
        },
        // 图审字典 批注意见-内 1 材料 2 图纸
        getAnnotationOpinions(type) {
            this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
                    Condition: "DicKey",
                    Keyword: type == 1 ? 'RG_AnnotationOpinions_CL' : 'RG_AnnotationOpinions_TS',
                }).then(res => {
                    if (res.Success) {
                        this.AnnotationOpinions = res.Data.DictionaryDetailsList || []
                    }
                })
        },
        // 图审字典
        // getOpinionClassification() {
        //     this.$http
        //         .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
        //             Condition: "DicKey",
        //             Keyword: 'ZS_OpinionClassification_TS_N',
        //         }).then(res => {
        //             if (res.Success) {
        //                 this.OpinionClassification = res.Data.DictionaryDetailsList || []
        //             }
        //         })
        // },
        upClick(item) {
            window.open(item.FileWebURL)
        },
        signSub(src) {
            this.signItem.ApproverSignature = src
            this.signVisible = false
        },
        getsignImg() {
            this.$http.post('/Base_ProjectManage/Project_ExternalUser/GetTextToImageForSign', {
                "Text": this.userInfo.RealName,
                "Width": this.userInfo.RealName.length ? Math.floor((window.innerHeight - 60) / this.userInfo.RealName.length) : 50,
                // "BackGroundColor": "#ffffff",
                // "FontFamily": "宋体",
                // "FontColor": "#D1100B",
                "FontWeight": true
            }).then(res => {
                if (res.Success) {
                    window.localStorage.setItem('signImg', res.Data)
                }
            })
        },
        getDataInfo() {
            this.$http.post('/ReviewGO/Review_ApprovalInfo/GetTheData', {
                id: this.RowId
            }).then(res => {
                if (res.Success) {
                    this.TheData = res.Data
                } else {
                    this.$phoneMessage({
                        message: res.Msg,
                    })
                }
            })
            this.$http.post('/ReviewGO/Review_ApprovalInfo_FAL/GetDataListByMoreCondition', {
                "Search": [
                    {
                        "Condition": "ApprovalId",
                        "Keyword": this.RowId
                    }
                ]
            }).then(res => {
                if (res.Success) {
                    this.UploadList = res.Data
                } else {
                    this.$phoneMessage({
                        message: res.Msg,
                    })
                }
            })
            this.$http.post('/ReviewGO/Review_ApprovalSignInfo/GetDataListByMoreCondition', {
                Search: [
                    {
                        "Condition": "ApprovalPhone",
                        "Keyword": this.userInfo.Phone
                    },
                    {
                        "Condition": "ApprovalId",
                        "Keyword": this.RowId
                    },
                ]
            }).then(res => {
                if (res.Success) {
                    this.signData = res.Data || []
                    this.signData.forEach((item, index) => {
                        this.$set(item, 'AuditUpFileData', [])
                        this.getitemUpList(item)
                        if (item.ApprovalStatus) {
                            this.$set(item, 'disabled', true)
                        } else {
                            this.$set(item, 'disabled', false)
                        }
                        // if (this.ApprovalType == 1) {
                        //     if (item.ApproverSignature) {
                        //         this.$set(item, 'disabled', true)
                        //     } else {
                        //         this.$set(item, 'disabled', false)
                        //     }
                        // } else if (this.ApprovalType == 2) {
                        //     if (item.ApprovalOpinions) {
                        //         this.$set(item, 'disabled', true)
                        //     } else {
                        //         this.$set(item, 'disabled', false)
                        //     }
                        // }

                        this.tabsList.push({
                            label: '待签' + index,
                            index: index
                        })
                    })

                } else {
                    this.$phoneMessage({
                        message: res.Msg,
                    })
                }
            })
        },
        getitemUpList(item) {
            this.$http.post('/ReviewGO/Review_ApprovalSignInfo_FAL/GetDataListBySingleCondition', {
                "Search":
                {
                    "Condition": "ApprovalSignId",
                    "Keyword": item.Id
                }

            }).then(res => {
                if (res.Success) {
                    res.Data.forEach(val => {
                        val.name = val.FileName
                        val.size = val.FileSize
                        val.url = val.FileWebURL
                        val.uid = val.Id
                        val.status = "success"
                    })
                    this.$set(item, 'AuditUpFileData', res.Data)
                } else {
                    this.$phoneMessage({
                        message: '附件列表获取失败',
                    })
                }
            })

        },
        getTaskData() {

            this.$http.post('/Base_TaskWork/Base_Task/GetBaseTaskForReviewGo', {
                "id": this.TaskId
            }).then(res => {
                if (res.Success) {
                    this.TaskData = res.Data
                } else {
                    this.$message.error(res.Msg)
                }
            })
        },
    },

    created() {
        this.userInfo = window.localStorage.getItem('RGInfo') ? JSON.parse(window.localStorage.getItem('RGInfo')) : {};
        this.isPCH5Text = this.getSystemInfo()
        window.addEventListener('resize', () => {

            this.isPCH5Text = this.getSystemInfo()
            console.log(this.isPCH5Text)
        })
        this.RowId = this.$route.query.Id
        this.type = this.$route.query.num
        this.TaskId = this.$route.query.TaskId
        this.ApprovalType = this.$route.query.ApprovalType
        console.log(this.$route)
        this.getDataInfo()
        this.getTaskData()
        // this.getShopData()
        this.getApprovalOpinions()
        this.getAnnotationOpinions()
        // this.getOpinionClassification()
        this.getsignImg()
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            this.isPCH5Text = this.getSystemInfo()
        })
    },
    computed: {},
    watch: {
        document: (e) => {
            console.log(e)
        }
    },
};
</script>
<style lang='scss' scoped>
.Shop_ApprovalInfoDetail {
    width: 100vw;
    min-height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    background: #eccece;
    position: relative;
}

.centent {
    padding: 10px;
}

.ellipsis {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 2; //内容限制的行数
}

.ellipsis1 {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 1; //内容限制的行数
}

::v-deep .el-form--label-top .el-form-item__label {
    padding: 0;
}

::v-deep .info {
    .el-descriptions-item__label {
        width: 30%;
    }
}



.Shop_ApprovalInfoList_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100vw;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}

.Shop_ApprovalInfoList_bottom_PC {
    position: fixed;
    bottom: 0;
    left: calc(30vw + 10px);
    height: 60px;
    width: calc(40vw - 20px);
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
}

.Shop_ApprovalInfoDetail_PC {
    width: 100vw;
    min-height: 100vh;
    padding: 0 30vw;
    box-sizing: border-box;
    background: #eccece;
    position: relative;
}
</style>